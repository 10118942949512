<template>
  <div>
    <div
      v-if="tableHeader"
      class="row row--justify-between mb-12"
    >
      <label class="fs-18 fw-med">
        <a
          v-if="linkTableHeader"
          :href="getUrl(tableHeader)"
          target="_blank"
        >
          {{ tableHeader }}
        </a>
        <span v-else>{{ tableHeader }}</span>
      </label>

      <base-button
        v-if="linkTableHeader"
        @click="resyncEntry(tableHeader, quickbooksData)"
        class="bg-blue fc-white"
        data-cy="add-quickbooks-button"
      >
        Sync Individual Entry
      </base-button>
    </div>

    <div class="Table mb-22">
      <div class="Table__head-wrapper row row--justify-between row">
        <label class="Table__header-lbl Table__header-lbl--BaseQuickbooksTable">Account</label>
        <label class="Table__header-lbl Table__header-lbl--BaseQuickbooksTable">DR</label>
        <label class="Table__header-lbl Table__header-lbl--BaseQuickbooksTable">CR</label>
        <label class="Table__header-lbl Table__header-lbl--BaseQuickbooksTable">Client</label>
        <label class="Table__header-lbl Table__header-lbl--BaseQuickbooksTable">Class</label>
      </div>
      <div
        v-for="(payment, index) in quickbooksData"
        :key="index"
        class="Table__item-wrapper Table__item-wrapper--BaseQuickbooksTable row"
      >
        <!-- Account -->
        <div
          class="column"
          :id="`Testing__QuickbooksAccount-${index}`"
        >
          <label class="fs-14 capitalize">
            {{ payment.credit_account || payment.debit_account }}
          </label>
        </div>

        <!-- DR -->
        <div
          class="column"
          :id="`Testing__QuickbooksDR-${index}`"
        >
          <label
            v-if="payment.debit_account"
            class="fs-14"
          >
            {{
              Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                .format((payment.debit / 100))
            }}
          </label>
        </div>

        <!-- CR -->
        <div
          class="column"
          :id="`Testing__QuickbooksCR-${index}`"
        >
          <label
            v-if="payment.credit_account"
            class="fs-14"
          >
            {{
              Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                .format((payment.credit / 100))
            }}
          </label>
        </div>

        <!-- CLIENT -->
        <div
          class="column"
          :id="`Testing__QuickbooksClient-${index}`"
        >
          <!-- Different endpoints have differently structured client data -->
          <!-- Client shortened name shouldn't be altered from underwriter/client's input -->
          <!-- https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1639 -->
          <label
            v-if="payment.client && payment.client.client"
            class="fs-14"
          >
            {{ payment.client.client.shortened_name }}
          </label>
          <label
            v-else-if="payment.client"
            class="fs-14"
          >
            {{ payment.client.shortened_name }}
          </label>
        </div>

        <!-- CLASS -->
        <label class="column fs-14 capitalize">
          {{ payment.class || "TBD" }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
// FUNCTIONALITY MANAGED BY BACKEND DEVS
// FUNCTIONALITY MANAGED BY BACKEND DEVS
// FUNCTIONALITY MANAGED BY BACKEND DEVS
import BaseButton from './base-button.vue'

export default {
  name: 'BaseQuickbooksTable',

  components: {
    BaseButton,
  },

  props: {
    quickbooksData: {
      type: Array,
      required: false,
      default: null
    },
    tableHeader: {
      type: String,
      required: false,
      default: null
    },
    linkTableHeader: {
      type: Boolean,
      required: false,
      default: false
    },
  },

  methods: {
    getUrl (name) {
      // eslint-disable-next-line max-len
      return `${process.env.VUE_APP_QUICKBOOKS_BASE_LINK_URL}app/search?searchCat=transaction&searchTransactionType=x999&searchValue=&searchAdvFieldArray=refno&searchAdvFieldLabelArray=Reference%20no.&searchAdvValueArray=${name}&searchAdvValueLabelArray=${name}&searchAdvOpArray=~`
    },

    async resyncEntry(name, entry) {
      console.log('name', name)
      console.log('entry', entry)
      const qbsync = localStorage.getItem('quickbooksSync')
      localStorage.setItem('quickbooksSync', JSON.stringify({
        date: new Date(JSON.parse(qbsync).date),
        entry_type: 'individualsync',
        name,
        entry,
        qbsync
      }))
      console.log('qbsync', JSON.parse(qbsync))

      window.location.replace(`${process.env.VUE_APP_BASE_URL}/api/quickbooksraw/requestToken`)
      console.log('hiii')
    }
  },
}
</script>

<style lang='sass'>
@import '../styles/tables'
</style>
