var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.tableHeader
      ? _c(
          "div",
          { staticClass: "row row--justify-between mb-12" },
          [
            _c("label", { staticClass: "fs-18 fw-med" }, [
              _vm.linkTableHeader
                ? _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.getUrl(_vm.tableHeader),
                        target: "_blank"
                      }
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.tableHeader) + "\n      "
                      )
                    ]
                  )
                : _c("span", [_vm._v(_vm._s(_vm.tableHeader))])
            ]),
            _vm.linkTableHeader
              ? _c(
                  "base-button",
                  {
                    staticClass: "bg-blue fc-white",
                    attrs: { "data-cy": "add-quickbooks-button" },
                    on: {
                      click: function($event) {
                        return _vm.resyncEntry(
                          _vm.tableHeader,
                          _vm.quickbooksData
                        )
                      }
                    }
                  },
                  [_vm._v("\n      Sync Individual Entry\n    ")]
                )
              : _vm._e()
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "Table mb-22" },
      [
        _vm._m(0),
        _vm._l(_vm.quickbooksData, function(payment, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass:
                "Table__item-wrapper Table__item-wrapper--BaseQuickbooksTable row"
            },
            [
              _c(
                "div",
                {
                  staticClass: "column",
                  attrs: { id: "Testing__QuickbooksAccount-" + index }
                },
                [
                  _c("label", { staticClass: "fs-14 capitalize" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          payment.credit_account || payment.debit_account
                        ) +
                        "\n        "
                    )
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "column",
                  attrs: { id: "Testing__QuickbooksDR-" + index }
                },
                [
                  payment.debit_account
                    ? _c("label", { staticClass: "fs-14" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD"
                              }).format(payment.debit / 100)
                            ) +
                            "\n        "
                        )
                      ])
                    : _vm._e()
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "column",
                  attrs: { id: "Testing__QuickbooksCR-" + index }
                },
                [
                  payment.credit_account
                    ? _c("label", { staticClass: "fs-14" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD"
                              }).format(payment.credit / 100)
                            ) +
                            "\n        "
                        )
                      ])
                    : _vm._e()
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "column",
                  attrs: { id: "Testing__QuickbooksClient-" + index }
                },
                [
                  payment.client && payment.client.client
                    ? _c("label", { staticClass: "fs-14" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(payment.client.client.shortened_name) +
                            "\n        "
                        )
                      ])
                    : payment.client
                    ? _c("label", { staticClass: "fs-14" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(payment.client.shortened_name) +
                            "\n        "
                        )
                      ])
                    : _vm._e()
                ]
              ),
              _c("label", { staticClass: "column fs-14 capitalize" }, [
                _vm._v(
                  "\n        " + _vm._s(payment.class || "TBD") + "\n      "
                )
              ])
            ]
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "Table__head-wrapper row row--justify-between row" },
      [
        _c(
          "label",
          {
            staticClass:
              "Table__header-lbl Table__header-lbl--BaseQuickbooksTable"
          },
          [_vm._v("Account")]
        ),
        _c(
          "label",
          {
            staticClass:
              "Table__header-lbl Table__header-lbl--BaseQuickbooksTable"
          },
          [_vm._v("DR")]
        ),
        _c(
          "label",
          {
            staticClass:
              "Table__header-lbl Table__header-lbl--BaseQuickbooksTable"
          },
          [_vm._v("CR")]
        ),
        _c(
          "label",
          {
            staticClass:
              "Table__header-lbl Table__header-lbl--BaseQuickbooksTable"
          },
          [_vm._v("Client")]
        ),
        _c(
          "label",
          {
            staticClass:
              "Table__header-lbl Table__header-lbl--BaseQuickbooksTable"
          },
          [_vm._v("Class")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }