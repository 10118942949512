<!-- Developed and Maintained by DJ Gagner -->
<template>
  <div class="DashboardEmployeeQuickbooks">
    <div class="row--align-center row--justify-between width-100 row mb-17">
      <label class="row row--width-auto fs-18 fw-med">
        Entries ({{ quickbooksTotal || 0 }})
      </label>

      <div class="row row--align-center row--width-auto">
        <base-date-picker
          v-model="date"
          :placeholder="'Date'"
          class="mr-12"
        />
        <v-select
          v-model="selectedFilter"
          @input="changeFilter"
          aria-label="Select input to choose search filter"
          class="DashboardEmployeeQuickbooks__select mr-12"
          :clearable="false"
          :options="Object.keys(filterOptions)"
        />
        <!-- payment type only shows up for debtor payments -->
        <v-select
          v-if="selectedFilter === 'Debtor Payments'"
          v-model="transactionType"
          aria-label="Select input to choose payment type filter"
          class="DashboardEmployeeQuickbooks__select-transaction-type mr-12"
          :clearable="false"
          :options="transactionTypeOptions"
        />
        <base-button
          @click="getQuickbooksData"
          class="bg-white fc-blue"
        >
          <i class="fa fa-search-left" />
          Search
        </base-button>

        <!-- will show up if selected day hasn't been synced or selected day is today -->
        <base-button
          v-if="showSync && !todaySelected"
          @click="quickbooksLogin"
          class="bg-blue fc-white ml-12"
        >
          Sync
        </base-button>

        <!-- this will ultimately be removed. We are keeping a grayed out button for testing -->
        <base-button
          v-if="showSync && todaySelected"
          @click="quickbooksLogin"
          class="bg-light fc-white ml-12"
        >
          Sync
        </base-button>
      </div>
    </div>

    <!-- eslint-disable-next-line max-len -->
    <!-- https://sentry.io/organizations/bobtail/issues/1759799010/?environment=production&project=5223295&query=is%3Aunresolved&statsPeriod=90d -->
    <!-- Without searching, the table(s) try to render before quickbooksData is updated, which causes errors -->
    <template v-if="quickbooksData && !searching">
      <!-- client payments -->
      <transition-group
        v-if="lastSearched === 'clientpayments'"
        name="Transition__fade"
      >
        <base-quickbooks-table
          v-for="(clients, index) in quickbooksData"
          :key="`ClientPayments-${index}`"
          :quickbooks-data="clients"
          :table-header="clients[0].label"
          :link-table-header="!(showSync && !todaySelected)"
        />
      </transition-group>
      <!-- balance adjustments -->
      <transition-group
        v-else-if="lastSearched === 'balance'"
        name="Transition__fade"
      >
        <base-quickbooks-table
          v-for="(batch_date, index) in Object.keys(quickbooksData)"
          :key="`BalanceAdjustments-${index}`"
          :quickbooks-data="quickbooksData[batch_date].data"
          :table-header="batch_date"
          :link-table-header="!(showSync && !todaySelected)"
        />
      </transition-group>

      <!-- debtor payments -->
      <transition-group
        v-else-if="lastSearched === 'debtorpayments'"
        name="Transition__fade"
      >
        <base-quickbooks-table
          v-for="(batch_date, index) in Object.keys(quickbooksData)"
          :key="`DebtorPayments-${index}`"
          :quickbooks-data="quickbooksData[batch_date].data"
          :table-header="quickbooksData[batch_date].label"
          :link-table-header="!(showSync && !todaySelected)"
        />
      </transition-group>
    </template>
  </div>
</template>

<script>
// FUNCTIONALITY MANAGED BY BACKEND DEVS
// FUNCTIONALITY MANAGED BY BACKEND DEVS
// FUNCTIONALITY MANAGED BY BACKEND DEVS

/* eslint-disable no-plusplus */
/* eslint-disable quote-props */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
// Packages
import moment from 'moment-timezone'
// Helpers
import {
  Balance,
  BatchPayment,
  DebtorPayment,
  Invoice,
  QuickbooksSync
} from '../../utils/api'
// Components
import BaseButton from '../../components/base-button.vue'
import BaseDatePicker from '../../components/base-date-picker.vue'
import BaseQuickbooksTable from '../../components/base-quickbooks-table.vue'
// Mixins
import {
  ValidationMixin
} from '../../utils/validation-mixin'

export default {
  name: 'DashboardEmployeeQuickbooks',

  components: {
    BaseButton,
    BaseDatePicker,
    BaseQuickbooksTable
  },

  mixins: [ValidationMixin],

  async created () {
    const qbsync = JSON.parse(localStorage.getItem('quickbooksSync'))
    try {
      this.date = new Date(qbsync.date)
      const qbsyncPath = qbsync.entry_type !== 'individualsync' ? qbsync.entry_type : qbsync.qbsync.entry_type;
      switch (qbsyncPath) {
        case 'debtorpayment':
          this.selectedFilter = 'Debtor Payments';
          this.selectedMethod = 'loadDebtorPayments';
          break
        case 'clientpayment':
          this.selectedFilter = 'Client Payments';
          this.selectedMethod = 'loadClientPayments'
          break
        case 'balance':
          this.selectedFilter = 'Balance Adjustments';
          this.selectedMethod = 'loadBalanceAdjustments'
          break
        default:
          break
      }
    } catch (err) {
      console.log('error loading previous values')
    }

    // run any pending quickbooks syncs
    // ?sync=1 is set on the backend
    const queryString = window.location.search
    if (queryString === '?sync=1') {
      // run the jobs

      if (qbsync) {
        let syncCompleted = false
        this.progressStart()

        try {
          switch (qbsync.entry_type) {
            case 'debtorpayment':
              await DebtorPayment.createJournalEntry(qbsync)
              syncCompleted = true
              break
            case 'clientpayment':
              for (const batchId of qbsync.batchIds) {
                await BatchPayment.createJournalEntry(batchId)
                syncCompleted = true
              }
              await Invoice.postBuyoutJournalEntries({ 'date': qbsync.date, 'entry_type': 'buyout' })
              syncCompleted = true

              break
            case 'individualsync':
              console.log(qbsync)
              await QuickbooksSync.syncOneJournalEntry(qbsync)
              syncCompleted = true

              break
            case 'balance':
              await Balance.createJournalEntry(qbsync)
              syncCompleted = true
              break
            default:
              break
          }

          // clear localstorage of quickbooksSync
          localStorage.removeItem('quickbooksSync')
          this.progressFinish()

          if (syncCompleted) {
            this.requestSuccess({ message: 'Quickbooks sync completed successfully' })
          }
        }
        catch (error) {
          localStorage.removeItem('quickbooksSync')
          this.CError('sync error:', error)
          // eslint-disable-next-line max-len
          // (https://sentry.io/organizations/bobtail/issues/1727897241/events/19c92d9357ec4189bf9550fbc8d45992/?environment=production&project=5223295&query=is%3Aunresolved)
          // Backend will return an error if the user tries to sync quickbooks before syncing the
          // clients. We don't want this specific error reported to Sentry.
          // We want the user to be notified instead of thinking something just broke.
          // Currently, there isn't a very defined error.response, so we have to parse the stack
          // TODO: Update `stack` to `message` when backend bug is fixed
          if (error.response.stack.toLowerCase().includes('make clients first')) {
            this.progressFinish()
            this.alertWarning('Please sync clients first')
            return
          }
          this.captureSentryEvent(
            'Dashboard Employee Quickbooks "Run Jobs"',
            {
              config: error.config,
              data: this.$data,
              details: error,
              props: this.$props,
              qbsync,
              queryString,
              response: error.response,
            }
          )
          this.requestFailure({
            message: error.response.data.message && error.response.data.message.length
              ? error.response.data.message
              : 'There was an error syncing Quickbooks'
          })
        }
      }
    }

    await this.getQuickbooksData()
  },

  data () {
    return {
      batchPrefixByTransactionType: {
        'Check': 'LB',
        'ACH': 'ACH'
      },
      date: new Date(),
      filterOptions: {
        'Client Payments': {
          method: 'loadClientPayments'
        },
        'Debtor Payments': {
          method: 'loadDebtorPayments'
        },
        'Balance Adjustments': {
          method: 'loadBalanceAdjustments'
        }
      },
      lastSearched: '',
      quickbooksData: [],
      quickbooksTotal: 0,
      searching: false,
      selectedFilter: 'Client Payments',
      selectedMethod: 'loadClientPayments',
      showSync: true,
      transactionType: 'Check',
      transactionTypeOptions: [
        'Check',
        'ACH'
      ]
    }
  },

  computed: {
    todaySelected() {
      return moment().startOf('day').isSame(moment(this.date).startOf('day'))
    }
  },

  methods: {
    changeFilter (filter) {
      this.selectedMethod = this.filterOptions[filter].method
    },

    async getQuickbooksData () {
      this.progressStart()

      try {
        this.searching = true
        const data = await this[this.selectedMethod]()
        if (!['Debtor Payments', 'Balance Adjustments'].includes(this.selectedFilter)) {
          this.quickbooksTotal = data.length
        }
        this.quickbooksData = data
        this.searching = false
        this.progressFinish()
      }
      catch (error) {
        this.captureSentryEvent(
          'Dashboard Employee Quickbooks "Get Quickbooks Data"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError('Error loading quickbooks data', error)
        this.requestFailure({ message: 'There was an error loading the data' })
      }
    },

    async loadBalanceAdjustments () {
      let syncs
      try {
        syncs = (await QuickbooksSync.queryList({
          day: moment(this.date).format('YYYY-MM-DD'),
          entry_type: 'balance'
        })).data
      }
      catch (error) {
        this.captureSentryEvent(
          'Dashboard Employee Quickbooks "Load Balance Adjustments"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the balance\'s adjustments' })
      }

      // if it already has a sync for today, hide it
      this.showSync = !syncs.rows.length

      localStorage.setItem('quickbooksSync', JSON.stringify({
        date: this.date,
        entry_type: 'balance'
      }))

      let entries
      try {
        entries = (await Balance.getJournalEntries({
          date: moment(this.date).startOf('day').toDate()
        })).data
      }
      catch (error) {
        this.captureSentryEvent(
          'Dashboard Employee Quickbooks "Get Journal Entries"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the balance\'s adjustments' })
      }

      Object.keys(entries).forEach(key => {
        const items = entries[key]
        // if the array is empty don't display it
        if (!items.length) {
          return delete entries[key]
        }

        const data = []
        let count = 0
        // make sure the debit & credit are in different rows
        items.forEach(item => {
          if (item.debit && item.debit_account) {
            data.push({
              client: item.client,
              debit: item.debit,
              class: item.class,
              debit_account: item.debit_account
            })
            count++
          }
          if (item.credit && item.credit_account) {
            data.push({
              client: item.client,
              credit: item.credit,
              class: item.class,
              credit_account: item.credit_account
            })
            count++
          }
        })
        this.quickbooksTotal = count

        entries[key] = {
          data,
          label: key
        }
      })

      this.lastSearched = 'balance'
      return entries
    },

    async loadClientPayments () {
      // load the batch payments
      let batchPayments;
      try {
        batchPayments = (await BatchPayment.queryList({
          gt___updated_at: moment(this.date).startOf('day').toDate(),
          lt___updated_at: moment(this.date).endOf('day').toDate(),
          status: 'sent'
        })).data.rows
      }
      catch (error) {
        this.captureSentryEvent(
          'Dashboard Employee Quickbooks "Load Client Payments"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue loading client payments' })
      }

      localStorage.setItem('quickbooksSync', JSON.stringify({
        entry_type: 'clientpayment',
        date: this.date,
        batchIds: batchPayments.map(batch => batch.id)
      }))

      // load the journal entries
      let syncs = []
      const data = []
      for (const batch of batchPayments) {
        let label
        if (batch.transfer_type === 'wire') {
          label = 'CP-WT'
        }
        else {
          label = 'CP-ACH'
        }
        label = `${label}-${moment(batch.created_at).format('MMDDYY-hh:MMA')}`

        let batchSyncs
        try {
          batchSyncs = (await QuickbooksSync.queryList({
            entry_type: `clientpayment-${batch.id}`
          })).data
        }
        catch (error) {
          this.captureSentryEvent(
            'Dashboard Employee Quickbooks "batchSyncs"',
            {
              batch,
              config: error.config,
              data: this.$data,
              details: error,
              props: this.$props,
              response: error.response,
            }
          )
          this.CError(error)
          this.requestFailure({ message: 'There was an issue loading client payments' })
        }

        let items
        try {
          items = (await BatchPayment.getJournalEntries(batch.id)).data
        }
        catch (error) {
          this.captureSentryEvent(
            'Dashboard Employee Quickbooks "Get Journal Entries"',
            {
              batch,
              config: error.config,
              data: this.$data,
              details: error,
              props: this.$props,
              response: error.response,
            }
          )
          this.CError(error)
          this.requestFailure({ message: 'There was an issue loading client payments' })
        }

        const subData = []
        syncs = syncs.concat(batchSyncs.rows)
        items.forEach(item => {
          // make sure the debit & credit are in different rows
          if (item.debit && item.debit_account) {
            subData.push({
              client: item.client,
              debit: item.debit,
              debit_account: item.debit_account,
              class: item.class,
              label
            })
          }
          if (item.credit && item.credit_account) {
            subData.push({
              client: item.client,
              credit: item.credit,
              credit_account: item.credit_account,
              class: item.class,
              label
            });
          }
        })
        if (subData.length) {
          data.push(subData)
        }
      }


      let buyoutEntries;
      try {
        buyoutEntries = (await Invoice.getBuyoutJournalEntries({
          date: moment(this.date).startOf('day').toDate()
        })).data
        // Balance.getJournalEntries({
        //   date: moment(this.date).startOf('day').toDate()
        // }))
      }
      catch (error) {
        this.captureSentryEvent(
          'Dashboard Employee Quickbooks "Get Journal Entries"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the buyouts' })
      }
      if (buyoutEntries.length) {
        const buyoutLabel = `BUYOUT-${moment(this.date).format('MMDDYY')}`
        buyoutEntries = buyoutEntries.map(buyoutEntry => {
          return {
            ...buyoutEntry,
            label: buyoutLabel
          };
        });
        data.push(buyoutEntries)
      }
      // if it already has a sync (or there is nothing to sync), hide it
      this.showSync = !syncs.length && data.length
      this.lastSearched = 'clientpayments'
      return data
    },

    async loadDebtorPayments() {
      const transaction_type = this.transactionType === 'Check'
        ? 'lockbox'
        : 'ach'

      localStorage.setItem('quickbooksSync', JSON.stringify({
        date: this.date,
        entry_type: 'debtorpayment',
        transaction_type
      }))

      let syncs
      try {
        syncs = (await QuickbooksSync.queryList({
          day: moment(this.date).format('YYYY-MM-DD'),
          entry_type: `debtorpayment-${transaction_type}`
        })).data
      }
      catch (error) {
        this.captureSentryEvent(
          'Dashboard Employee Quickbooks "Load Debtor Payments Syncs"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue loading debtor payments' })
      }

      // if it already has a sync for today, hide it
      this.showSync = !syncs.rows.length

      let entries
      try {
        entries = (await DebtorPayment.getJournalEntries({
          date: moment(this.date).startOf('day').toDate(),
          transaction_type
        })).data
      }
      catch (error) {
        this.captureSentryEvent(
          'Dashboard Employee Quickbooks "Load Debtor Payments entries"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue loading debtor payments' })
      }

      let data = []
      Object.keys(entries).forEach(key => {
        data = data.concat(entries[key])
        entries[key] = {
          data: entries[key],
          label: `DP-${this.batchPrefixByTransactionType[this.transactionType]}-${key}`
        }
      })

      this.quickbooksTotal = data.length
      this.lastSearched = 'debtorpayments'
      return entries
    },

    async quickbooksLogin () {
      return window.location.replace(`${process.env.VUE_APP_BASE_URL}/api/quickbooksraw/requestToken`)
    }
  }
}
</script>

<style lang="sass">
@import '../../styles/tables'

.DashboardEmployeeQuickbooks

  &__select
    width: rem(220px)

  &__select-transaction-type
    width: rem(120px)
</style>
